var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observerRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('form',{staticClass:"px-6",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitStep)}}},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pricingName'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pricingName'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pricingName'
            )},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.vehicleType'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.vehicleType'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.vehicleType'
            ),"placeholder":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.placeHolder.vehicleType'
            ),"value-attribute":"value","text-attribute":"text","options":_vm.vehicleTypes,"hide-search-box":""},model:{value:(_vm.form.bike_category),callback:function ($$v) {_vm.$set(_vm.form, "bike_category", $$v)},expression:"form.bike_category"}})],1)]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2 "},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.fleet'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.fleet'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.fleet'
            ),"placeholder":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.placeHolder.fleet'
            ),"value-attribute":"id","text-attribute":"name","options":_vm.fleets,"hide-search-box":""},model:{value:(_vm.form.fleet),callback:function ($$v) {_vm.$set(_vm.form, "fleet", $$v)},expression:"form.fleet"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"rules":"","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.fleetCurrency'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.fleetCurrency'
            ),"isInfoEnabled":false,"value":_vm.selectedFleetCurrency ||
              _vm.$t(
                'components.billingPlanManagement.addEdit.steps.ride.placeHolder.fleet'
              ),"disabled":""}})],1)]),_c('div',{staticClass:"flex items-start py-4"},[_c('ValidationProvider',{attrs:{"vid":"tax_enabled","name":" ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.taxPlan'
            )}}),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center mt-px ml-2 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tax_enabled),expression:"form.tax_enabled"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"form.tax_enabled"},domProps:{"value":true,"checked":_vm._q(_vm.form.tax_enabled,true)},on:{"change":function($event){return _vm.$set(_vm.form, "tax_enabled", true)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.yes' )))])]),_c('label',{staticClass:"flex items-center mt-px ml-4 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tax_enabled),expression:"form.tax_enabled"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"form.tax_enabled"},domProps:{"value":false,"checked":_vm._q(_vm.form.tax_enabled,false)},on:{"change":function($event){return _vm.$set(_vm.form, "tax_enabled", false)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(" "+_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.no' ))+" ")])])])]}}],null,true)})],1),(_vm.form.tax_enabled)?_c('div',{staticClass:"flex items-start mt-5"},[_c('div',{staticClass:"w-full "},[_c('AppInput',{attrs:{"type":"richselect","rules":_vm.form.tax_enabled ? 'required' : '',"name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.chooseTaxPlan'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.chooseTaxPlan'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.chooseTaxPlan'
            ),"placeholder":_vm.getTaxPlanPlaceholder,"value-attribute":"value","text-attribute":"text","disabled":_vm.getTaxPlanDisabledState,"options":_vm.modifiedTaxPlan,"hide-search-box":""},model:{value:(_vm.form.tax_plan),callback:function ($$v) {_vm.$set(_vm.form, "tax_plan", $$v)},expression:"form.tax_plan"}}),(!_vm.isTaxPlanLoading && _vm.taxPlans.length === 0)?_c('p',{staticClass:"text-sm text-gray-500"},[_c('RouterLink',{staticClass:"text-blue-600 capitalize font-semibold",attrs:{"to":{
              name: 'ViewTaxPlan',
            },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.createTaxPlan' ))+" ")]),_vm._v(" "+_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.placeHolder.chooseTaxPlan.atFirst' ))+" ")],1):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2 "},[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":_vm.isP2PUser ? '' : 'required|between:0,1000',"name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.vehicleReservationPrice'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.vehicleReservationPrice'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.vehicleReservationPrice'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.vehicle_reservation_fees),"haveUnitText":true,"disabled":_vm.isP2PUser,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.vehicle_reservation_fees),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_reservation_fees", _vm._n($$v))},expression:"form.vehicle_reservation_fees"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","rules":"required","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.unlockCharge'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.unlockCharge'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.unlockCharge'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.vehicle_unlock_fees),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.vehicle_unlock_fees),callback:function ($$v) {_vm.$set(_vm.form, "vehicle_unlock_fees", _vm._n($$v))},expression:"form.vehicle_unlock_fees"}})],1)]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.billingUnitMins'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.billingUnitMins'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.billingUnitMins'
            ),"haveUnitText":true,"unitText":"Mins"},model:{value:(_vm.form.billing_unit_mins),callback:function ($$v) {_vm.$set(_vm.form, "billing_unit_mins", $$v)},expression:"form.billing_unit_mins"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.firstUnitFees'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.firstUnitFees'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.firstUnitFees'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.first_unit_fees),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.first_unit_fees),callback:function ($$v) {_vm.$set(_vm.form, "first_unit_fees", $$v)},expression:"form.first_unit_fees"}})],1)]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2 "},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.defaultUnitFees'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.defaultUnitFees'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.defaultUnitFees'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.default_unit_fees),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.default_unit_fees),callback:function ($$v) {_vm.$set(_vm.form, "default_unit_fees", $$v)},expression:"form.default_unit_fees"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.maxBillingPerRide'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.maxBillingPerRide'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.maxBillingPerRide'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.max_billing_per_ride),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.max_billing_per_ride),callback:function ($$v) {_vm.$set(_vm.form, "max_billing_per_ride", $$v)},expression:"form.max_billing_per_ride"}})],1)]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.minBillingPerRide'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.minBillingPerRide'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.minBillingPerRide'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.minimum_ride_fee),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.minimum_ride_fee),callback:function ($$v) {_vm.$set(_vm.form, "minimum_ride_fee", $$v)},expression:"form.minimum_ride_fee"}})],1)]),_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-full"},[_c('AppInput',{attrs:{"type":"number","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.minWalletBalanceShouldTakeTrip'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.minWalletBalanceShouldTakeTrip'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.minWalletBalanceShouldTakeTrip'
            ),"haveSuggestion":_vm.form.tax_enabled,"suggestionText":_vm.getVatIncludedAmount(_vm.form.minimum_trip_balance),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.minimum_trip_balance),callback:function ($$v) {_vm.$set(_vm.form, "minimum_trip_balance", $$v)},expression:"form.minimum_trip_balance"}})],1)]),_c('div',{staticClass:"flex items-center py-2"},[_c('ValidationProvider',{attrs:{"vid":"status","name":" ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.status'
            )}}),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center mt-px ml-2 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status_active),expression:"form.status_active"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"status"},domProps:{"value":true,"checked":_vm._q(_vm.form.status_active,true)},on:{"change":function($event){return _vm.$set(_vm.form, "status_active", true)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.active' )))])]),_c('label',{staticClass:"flex items-center mt-px ml-4 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.status_active),expression:"form.status_active"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"status"},domProps:{"value":false,"checked":_vm._q(_vm.form.status_active,false)},on:{"change":function($event){return _vm.$set(_vm.form, "status_active", false)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.inactive' )))])])])]}}],null,true)})],1),_c('div',{staticClass:"flex items-start py-2"},[_c('ValidationProvider',{attrs:{"vid":"pause_ride_fee_same_as_billing","name":" ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseRideFeeSameAsAbove'
            )}}),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center mt-px ml-2 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pause_ride_fee_same_as_billing),expression:"form.pause_ride_fee_same_as_billing"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"pause_ride_fee_same_as_billing"},domProps:{"value":true,"checked":_vm._q(_vm.form.pause_ride_fee_same_as_billing,true)},on:{"change":function($event){return _vm.$set(_vm.form, "pause_ride_fee_same_as_billing", true)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(" "+_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.yes' ))+" ")])]),_c('label',{staticClass:"flex items-center mt-px ml-4 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.pause_ride_fee_same_as_billing),expression:"form.pause_ride_fee_same_as_billing"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"pause_ride_fee_same_as_billing"},domProps:{"value":false,"checked":_vm._q(_vm.form.pause_ride_fee_same_as_billing,false)},on:{"change":function($event){return _vm.$set(_vm.form, "pause_ride_fee_same_as_billing", false)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.no' )))])])])]}}],null,true)})],1),(!_vm.form.pause_ride_fee_same_as_billing)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.taxAppliedOnPauses'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.taxAppliedOnPauses'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.taxAppliedOnPauses'
            ),"placeholder":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.placeHolder.taxAppliedOnPauses'
            ),"value-attribute":"value","text-attribute":"text","options":[
            { text: 'Yes', value: true },
            { text: 'No', value: false } ],"hide-search-box":""},model:{value:(_vm.form.tax_applies_to_pause_fees),callback:function ($$v) {_vm.$set(_vm.form, "tax_applies_to_pause_fees", $$v)},expression:"form.tax_applies_to_pause_fees"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"1","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseBillingUnitMins'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseBillingUnitMins'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pauseBillingUnitMins'
            ),"haveUnitText":true,"unitText":"Mins"},model:{value:(_vm.form.pause_billing_unit_mins),callback:function ($$v) {_vm.$set(_vm.form, "pause_billing_unit_mins", $$v)},expression:"form.pause_billing_unit_mins"}})],1)]):_vm._e(),(!_vm.form.pause_ride_fee_same_as_billing)?_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"w-1/2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseFirstUnitFees'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseFirstUnitFees'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pauseFirstUnitFees'
            ),"haveSuggestion":_vm.form.tax_enabled && _vm.form.tax_applies_to_pause_fees,"suggestionText":_vm.getVatIncludedAmount(_vm.form.pause_first_unit_fees),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.pause_first_unit_fees),callback:function ($$v) {_vm.$set(_vm.form, "pause_first_unit_fees", $$v)},expression:"form.pause_first_unit_fees"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseNextUnitFees'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.pauseNextUnitFees'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.pauseNextUnitFees'
            ),"haveSuggestion":_vm.form.tax_enabled && _vm.form.tax_applies_to_pause_fees,"suggestionText":_vm.getVatIncludedAmount(_vm.form.pause_default_unit_fees),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.pause_default_unit_fees),callback:function ($$v) {_vm.$set(_vm.form, "pause_default_unit_fees", $$v)},expression:"form.pause_default_unit_fees"}})],1)]):_vm._e(),_c('div',{staticClass:"flex items-center py-2"},[_c('ValidationProvider',{attrs:{"vid":"is_pre_auth_required","name":" ","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.preAuthRequired'
            )}}),_c('div',{staticClass:"flex"},[_c('label',{staticClass:"flex items-center mt-px ml-2 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.is_pre_auth_required),expression:"form.is_pre_auth_required"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"is_pre_auth_required"},domProps:{"value":true,"checked":_vm._q(_vm.form.is_pre_auth_required,true)},on:{"change":function($event){return _vm.$set(_vm.form, "is_pre_auth_required", true)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.yes' )))])]),_c('label',{staticClass:"flex items-center mt-px ml-4 cursor-pointer"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.is_pre_auth_required),expression:"form.is_pre_auth_required"}],staticClass:"p-1 form-radio",attrs:{"type":"radio","name":"is_pre_auth_required"},domProps:{"value":false,"checked":_vm._q(_vm.form.is_pre_auth_required,false)},on:{"change":function($event){return _vm.$set(_vm.form, "is_pre_auth_required", false)}}}),_c('span',{staticClass:"ml-2 text-sm"},[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.no' )))])])])]}}],null,true)})],1),(_vm.form.is_pre_auth_required)?_c('div',{staticClass:"flex items-start py-2"},[_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","step":"0.01","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.preAuthHoldAmount'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.preAuthHoldAmount'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.preAuthHoldAmount'
            ),"haveUnitText":true,"unitText":_vm.selectedFleetCurrencyFormatted},model:{value:(_vm.form.pre_auth_hold_amount),callback:function ($$v) {_vm.$set(_vm.form, "pre_auth_hold_amount", $$v)},expression:"form.pre_auth_hold_amount"}})],1),_c('div',{staticClass:"w-1/2 pl-2"},[_c('AppInput',{attrs:{"type":"richselect","rules":"required","name":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.preAuthRideCondition'
            ),"label":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.title.preAuthRideCondition'
            ),"infoDescription":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.infoDescription.preAuthRideCondition'
            ),"placeholder":_vm.$t(
              'components.billingPlanManagement.addEdit.steps.ride.placeHolder.preAuthRideCondition'
            ),"value-attribute":"value","text-attribute":"name","options":_vm.rideTypeChoice,"hide-search-box":""},model:{value:(_vm.form.pre_auth_ride_condition),callback:function ($$v) {_vm.$set(_vm.form, "pre_auth_ride_condition", $$v)},expression:"form.pre_auth_ride_condition"}})],1)]):_vm._e(),(_vm.isRentPackageShow)?[_c('div',{staticClass:"flex items-center mb-3 font-bold text-gray-500 mt-5"},[_c('span',[_vm._v(_vm._s(_vm.$t( 'components.billingPlanManagement.addEdit.steps.ride.title.rentPackages' )))])]),_vm._l((_vm.rentalPackages),function(rentalPackage,rentalPackageIndex){return _c('section',{key:rentalPackageIndex},[_c('div',{staticClass:"flex items-center my-5 text-sm font-bold text-gray-500 "},[_c('span',[_vm._v(_vm._s(_vm.getRentalPackageTitle(rentalPackage.value)))]),_c('div',{staticClass:"col-span-1 ml-2 cursor-pointer focus:text-gray-400",on:{"click":function($event){return _vm.onAddRentalPackage(rentalPackageIndex, rentalPackage.value)}}},[_c('i',{staticClass:"fas fa-plus-circle",staticStyle:{"color":"black"}})])]),_vm._l((rentalPackage.data),function(packageData,packageDataIndex){return _c('div',{key:packageDataIndex},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-start  gap-x-1 w-11/12  "},[_c('div',{staticClass:"w-6/12   "},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.rentalType'
                    ),"label":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.rentalType'
                    ),"infoDescription":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.infoDescription.rentalType'
                    ),"placeholder":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.placeHolder.rentalType'
                    ),"value-attribute":"value","text-attribute":"display_name","options":_vm.rentalPackageTypeOptions[rentalPackage.value],"disabled":packageData.typeDisabled,"hide-search-box":""},model:{value:(packageData.period),callback:function ($$v) {_vm.$set(packageData, "period", $$v)},expression:"packageData.period"}})],1),_c('div',{staticClass:"w-6/12   "},[_c('AppInput',{attrs:{"type":"text","rules":"required","name":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.rentalCost'
                    ),"label":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.title.rentalCost'
                    ),"infoDescription":_vm.$t(
                      'components.billingPlanManagement.addEdit.steps.ride.infoDescription.rentalCost'
                    ),"placeholder":"e.g. 5","haveSuggestion":parseFloat(packageData.cost) ? true : false,"isSuggestionAlert":_vm.getSavePercentage(
                      parseFloat(packageData.period),
                      parseFloat(packageData.cost),
                      rentalPackage.value
                    ) < 0
                      ? true
                      : false,"suggestionText":((_vm.form.tax_enabled
                        ? _vm.getVatIncludedAmount(packageData.cost)
                        : '') + " " + (_vm.form.tax_enabled ? ', ' : '') + "Save " + (_vm.getSavePercentage(
                      parseFloat(packageData.period),
                      parseFloat(packageData.cost),
                      rentalPackage.value
                    )) + "%"),"haveUnitText":true,"unitText":_vm.selectedFleetCurrency},model:{value:(packageData.cost),callback:function ($$v) {_vm.$set(packageData, "cost", _vm._n($$v))},expression:"packageData.cost"}})],1)]),_c('div',{staticClass:"flex  w-1/12 item-center justify-center"},[_c('div',{staticClass:"col-span-1 cursor-pointer focus:text-gray-400",on:{"click":function($event){return _vm.onRemoveRentPackage(rentalPackageIndex, packageDataIndex)}}},[_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}})])])]),_c('div',{staticClass:"pb-2"},[_c('section',[_c('t-checkbox',{attrs:{"wrapped":"","name":_vm.$t(
                    'components.billingPlanManagement.addEdit.steps.ride.title.addNoteCheckbox'
                  ),"label":_vm.$t(
                    'components.billingPlanManagement.addEdit.steps.ride.title.addNoteCheckbox'
                  )},model:{value:(packageData.addDescriptionUI),callback:function ($$v) {_vm.$set(packageData, "addDescriptionUI", $$v)},expression:"packageData.addDescriptionUI"}})],1),_c('section',{directives:[{name:"show",rawName:"v-show",value:(packageData.addDescriptionUI),expression:"packageData.addDescriptionUI"}]},[_c('AppInput',{attrs:{"rules":"","name":_vm.$t(
                    'components.billingPlanManagement.addEdit.steps.ride.title.notes'
                  ),"label":_vm.$t(
                    'components.billingPlanManagement.addEdit.steps.ride.title.notes'
                  ),"type":"textarea"},model:{value:(packageData.description),callback:function ($$v) {_vm.$set(packageData, "description", $$v)},expression:"packageData.description"}})],1)])])})],2)})]:_vm._e(),_c('div',{staticClass:"w-full "},[_c('AppInput',{attrs:{"type":"textarea","name":_vm.$t(
            'components.billingPlanManagement.addEdit.steps.ride.title.billingDescription'
          ),"label":_vm.$t(
            'components.billingPlanManagement.addEdit.steps.ride.title.billingDescription'
          ),"infoDescription":_vm.$t(
            'components.billingPlanManagement.addEdit.steps.ride.infoDescription.billingDescription'
          )},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }