<template>
  <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
    <loading :active.sync="isLoading" :is-full-page="false" />

    <form @submit.prevent="handleSubmit(submitStep)" class="px-6">
      <!-- payment -->
      <section>
        <!-- row-1 -->
        <div>
          <AppInput
            v-model="form.payment.payment_gateway"
            type="richselect"
            name="Payment Gateway"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.paymentGateway'
              )
            "
            rules="required"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.paymentGateway'
              )
            "
            :placeholder="
              $t(
                'components.organizationManagement.addEdit.steps.settings.placeHolder.paymentGateway'
              )
            "
            text-attribute="name"
            value-attribute="id"
            :options="paymentGateways"
            hide-search-box
          />
        </div>
        <!-- /row-1 -->

        <div class="grid grid-cols-2 gap-4 pb-4" v-if="isHideEnableCheckbox">
          <TCheckbox
            v-model="form.payment.sandbox_mode"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableLiveMode'
              )
            "
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableLiveMode'
              )
            "
            wrapped
          />
        </div>
        <div v-if="isItPayoneMethod">
          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.payone_mid"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneMID'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneMID'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneMID'
                )
              "
            />

            <AppInput
              v-model="form.payment.payone_portalid"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payonePortalID'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payonePortalID'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payonePortalID'
                )
              "
            />
          </div>
          <!-- /row-2 -->

          <!-- row-3 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.payone_aid"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneAID'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneAID'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneAID'
                )
              "
            />

            <AppInput
              v-model="form.payment.payone_key"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneKey'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.payoneKey'
                )
              "
            />
          </div>
          <!-- /row-3 -->
        </div>

        <div v-else-if="isItKKiaPayMethod">
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.kkiapay_sandbox_public_key"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxPublicKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxPublicKey'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxPublicKey'
                )
              "
            />
            <AppInput
              v-model="form.payment.kkiapay_live_public_key"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLivePublicKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLivePublicKey'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLivePublicKey'
                )
              "
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.kkiapay_sandbox_private_key"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxPrivateKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxPrivateKey'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxPrivateKey'
                )
              "
            />

            <AppInput
              v-model="form.payment.kkiapay_live_private_key"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLivePrivateKey'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLivePrivateKey'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLivePrivateKey'
                )
              "
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.kkiapay_sandbox_secret"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxSecret'
                )
              "
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxSecret'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPaySandboxSecret'
                )
              "
            />
            <AppInput
              v-model="form.payment.kkiapay_live_secret"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLiveSecret'
                )
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLiveSecret'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.kKiaPayLiveSecret'
                )
              "
            />
          </div>
        </div>
        <div v-else-if="isItMyFatoorahMethod">
          <div v-if="!form.payment.sandbox_mode">
            <AppInput
              v-model="form.payment.sandbox_secret_key"
              type="password"
              :name="`Test ${getSecretKeyLabel}`"
              rules="required"
              :label="`Test ${getSecretKeyLabel}`"
              :placeholder="getSecretKeyLabel"
            />
          </div>
          <div v-else>
            <AppInput
              v-model="form.payment.secret_key"
              type="password"
              :name="getSecretKeyLabel"
              rules="required"
              :label="getSecretKeyLabel"
              :placeholder="getSecretKeyLabel"
            />
            <AppInput
              v-model="form.payment.src_url"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.javascriptSdkUrl'
                )
              "
              rules="required"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.javascriptSdkUrl'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.placeHolder.javascriptSdkUrl'
                )
              "
            />
            <AppInput
              v-model="form.payment.api_url"
              type="password"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.apiBaseUrl'
                )
              "
              rules="required"
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.apiBaseUrl'
                )
              "
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.placeHolder.apiBaseUrl'
                )
              "
            />
          </div>
        </div>
        <div v-else-if="isItBeePayMethod">
          <AppInput
            v-model="form.payment.client_id"
            type="password"
            :name="`Client Id`"
            rules="required"
            :label="`Client Id`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.secret_key"
            type="password"
            :name="`Secret Key`"
            rules="required"
            :label="`Secret Key`"
            :placeholder="``"
          />

          <AppInput
            v-model="form.payment.user_name"
            type="password"
            :name="`Username`"
            rules="required"
            :label="`Username`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.password"
            type="password"
            :name="`Password`"
            rules="required"
            :label="`Password`"
            :placeholder="``"
          />

          <AppInput
            v-model="form.payment.api_url"
            type="password"
            :name="`Base URL`"
            rules="required"
            :label="`Base URL`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.beepay_acess_token_url"
            type="password"
            :name="`Access Token URL`"
            rules="required"
            :label="`Access Token URL`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.beepay_scope_url"
            type="password"
            :name="`Scope URL`"
            rules="required"
            :label="`Scope URL`"
            :placeholder="``"
          />
        </div>
        <div v-else-if="isItMxPlusMethod">
          <AppInput
            v-model="form.payment.client_id"
            type="password"
            :name="`Merchant ID`"
            rules="required"
            :label="`Merchant ID`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.secret_key"
            type="password"
            :name="`CX Password`"
            rules="required"
            :label="`CX Password`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.extra_key"
            type="password"
            :name="`RSA Private Key`"
            rules="required"
            :label="`RSA Private Key`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.app_key"
            type="password"
            :name="`Institution ID`"
            rules="required"
            :label="`Institution ID`"
            :placeholder="``"
          />
          <AppInput
            v-model="form.payment.website_id"
            type="password"
            :name="`Website ID`"
            rules="required"
            :label="`Website ID`"
            :placeholder="``"
          />
        </div>

        <div v-else>
          <!-- row-2 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.sandbox_client_id"
              type="password"
              :name="`${getClientIdLabel}`"
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :infoDescription="
                `Please provide ${getClientIdLabel} for testing purpose`
              "
              :label="`${getClientIdLabel}`"
              :placeholder="`${getClientIdLabel}`"
            />
            <AppInput
              v-model="form.payment.client_id"
              type="password"
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :name="`Live ${getClientIdLabel}`"
              :infoDescription="
                `Please provide ${getClientIdLabel} for production purpose`
              "
              :label="`Live ${getClientIdLabel}`"
              :placeholder="`Live ${getClientIdLabel}`"
            />
          </div>
          <!-- /row-3 -->

          <!-- row-3 -->
          <div class="grid grid-cols-2 gap-4">
            <AppInput
              v-model="form.payment.sandbox_secret_key"
              type="password"
              :name="getSecretKeyLabel"
              :rules="!form.payment.sandbox_mode ? 'required' : ''"
              :infoDescription="
                `Please provide ${getSecretKeyLabel} for testing purpose`
              "
              :label="getSecretKeyLabel"
              :placeholder="getSecretKeyLabel"
            />

            <AppInput
              v-model="form.payment.secret_key"
              type="password"
              :name="`Live ${getSecretKeyLabel}`"
              :infoDescription="
                `Please provide ${getSecretKeyLabel} for production purpose`
              "
              :rules="!form.payment.sandbox_mode ? '' : 'required'"
              :label="`Live ${getSecretKeyLabel}`"
              :placeholder="`Live ${getSecretKeyLabel}`"
            />
          </div>
          <!-- /row-3 -->

          <div v-if="isItStripeMethod">
            <AppInput
              type="richselect"
              :name="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.supportedPaymentMethodTypes'
                )
              "
              :label="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.title.supportedPaymentMethodTypes'
                )
              "
              rules=""
              :options="supportedPaymentMethodTypes"
              :placeholder="
                $t(
                  'components.organizationManagement.addEdit.steps.settings.placeHolder.supportedPaymentMethodTypes'
                )
              "
              :hide-search-box="true"
              v-model="form.payment.supported_payment_method_types"
              multiple
            />
          </div>
        </div>

        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.regular.is_wallet_payment_enabled"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableWalletPaymentMethod'
              )
            "
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableWalletPaymentMethod'
              )
            "
            wrapped
          />
          <TCheckbox
            v-model="form.regular.is_cash_payment_enabled"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableCashPaymentMethod'
              )
            "
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableCashPaymentMethod'
              )
            "
            wrapped
          />
        </div>
        <div class="grid grid-cols-2 gap-4 pb-4">
          <TCheckbox
            v-model="form.regular.is_on_ride_payment_enabled"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableRidePayment'
              )
            "
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableRidePayment'
              )
            "
            wrapped
          />
          <TCheckbox
            v-model="form.regular.is_payment_method_tokenization_supported"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableTokenizationPaymentMethod'
              )
            "
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.checkBox.enableTokenizationPaymentMethod'
              )
            "
            wrapped
          />
        </div>
        <div>
          <AppInput
            rules=""
            type="number"
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.maxCreditCardCount'
              )
            "
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.maxCreditCardCount'
              )
            "
            placeholder="e.g. 10"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.maxCreditCardCount'
              )
            "
            v-model="form.regular.max_credit_card_count"
          />
        </div>
      </section>
      <!-- /payment -->
      <!-- security -->
      <section>
        <div class="my-5 text-sm font-bold text-gray-500">
          <span>
            {{
              $t(
                'components.organizationManagement.addEdit.steps.settings.subText.security'
              )
            }}</span
          >
        </div>
        <div>
          <AppInput
            rules=""
            type="number"
            name="OTP Rate limit"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.OTPRateLimit'
              )
            "
            placeholder="e.g. 4"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.OTPRateLimit'
              )
            "
            v-model="form.regular.otp_rate_limit"
          />
        </div>
        <div>
          <AppInput
            v-model="form.regular.otp_rate_mode"
            type="richselect"
            name="Rate Unit"
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.rateUnit'
              )
            "
            rules=""
            :placeholder="
              $t(
                'components.organizationManagement.addEdit.steps.settings.placeHolder.rateUnit'
              )
            "
            text-attribute="text"
            value-attribute="value"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.rateUnit'
              )
            "
            :options="getOtpRateModeOptions"
            hide-search-box
          />
        </div>
      </section>
      <!-- /security -->

      <!-- domains -->
      <section>
        <!-- <div class="section-name" v-text="`Domains`" /> -->
        <div class="flex items-center my-5 text-sm font-bold text-gray-500">
          <span>
            {{
              $t(
                'components.organizationManagement.addEdit.steps.settings.subText.manageIoT'
              )
            }}</span
          >
          <div
            class="col-span-1 ml-2 cursor-pointer focus:text-gray-400"
            @click="onAddLockTypeField"
          >
            <i class="fas fa-plus-circle" style="color: black"></i>
          </div>
        </div>

        <!-- {{ lockTypes }} -->
        <div
          class="flex items-center"
          v-for="(item, index) in lockTypes"
          :key="index"
        >
          <div class="flex gap-2 items-center w-11/12">
            <div class="w-4/12">
              <AppInput
                v-model="item.type"
                type="richselect"
                :name="`IoT Type ${index}`"
                :infoDescription="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.infoDescription.type'
                  )
                "
                :label="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.title.type'
                  )
                "
                value-attribute="value"
                text-attribute="display_name"
                :placeholder="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.placeHolder.type'
                  )
                "
                :options="lockIots"
                hide-search-box
              />
            </div>

            <div class="w-4/12">
              <AppInput
                v-model="item.domain"
                type="text"
                :name="`Domain ${index}`"
                :infoDescription="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.infoDescription.domain'
                  )
                "
                rules=""
                :label="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.title.domain'
                  )
                "
                :placeholder="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.placeHolder.domain'
                  )
                "
              />
            </div>

            <div class="w-4/12">
              <AppInput
                v-model="item.port"
                type="number"
                :name="`Port ${index}`"
                :infoDescription="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.infoDescription.port'
                  )
                "
                rules=""
                :label="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.title.port'
                  )
                "
                :placeholder="
                  $t(
                    'components.organizationManagement.addEdit.steps.settings.placeHolder.port'
                  )
                "
              />
            </div>
          </div>

          <div class="flex justify-center w-1/12 item-center">
            <div
              class="col-span-1 cursor-pointer focus:text-gray-400"
              @click="onRemoveLockTypeField(index)"
            >
              <i class="fas fa-minus-circle" style="color: #d90a20"></i>
            </div>
          </div>
        </div>
      </section>
      <!-- /domains -->

      <!-- Okai Dashboard Configuration -->
      <section v-if="isOkaiIotType">
        <div class="my-5 text-sm font-bold text-gray-500">
          <span>
            {{
              $t(
                'components.organizationManagement.addEdit.steps.settings.subText.okaiDashboardConfiguration'
              )
            }}</span
          >
        </div>
        <div>
          <AppInput
            rules="required"
            type="String"
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.okaiCommandFramePassword'
              )
            "
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.okaiCommandFramePassword'
              )
            "
            placeholder="e.g. 4xhI4fk"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.okaiCommandFramePassword'
              )
            "
            v-model="form.regular.okai_command_frame_pass"
          />
        </div>
      </section>

      <!-- Segway Dashboard Configuration -->
      <section v-if="isSegwayIotType">
        <div class="my-5 text-sm font-bold text-gray-500">
          <span>{{
            $t(
              'components.organizationManagement.addEdit.steps.settings.subText.segwayDashboardConfiguration'
            )
          }}</span>
        </div>
        <div>
          <AppInput
            rules="required"
            type="text"
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwayPlatformCode'
              )
            "
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwayPlatformCode'
              )
            "
            placeholder="e.g. 4xhI4fk"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.segwayPlatformCode'
              )
            "
            v-model="form.regular.segway_client_id"
          />
        </div>

        <div>
          <AppInput
            rules="required"
            type="text"
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwaySecretKey'
              )
            "
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwaySecretKey'
              )
            "
            placeholder="e.g. HkiC-45k"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.segwaySecretKey'
              )
            "
            v-model="form.regular.segway_client_secret"
          />
        </div>
        <div>
          <AppInput
            rules="required"
            type="text"
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwayPortalBaseURL'
              )
            "
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwayPortalBaseURL'
              )
            "
            placeholder="e.g. portal.website.com"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.segwayPortalBaseURL'
              )
            "
            :haveSuggestion="true"
            :suggestionText="
              `For the APAC region, the platform code is A***** with the domain <b>https://apac-api.segwaydiscovery.com</b>. For Europe, the platform code is E***** with the domain <b>https://eu-api.segwaydiscovery.com</b>. The Americas region is represented by the platform code U***** and the domain <b>https://us-api.segwaydiscovery.com</b>. Lastly, for China, the platform code is C***** and the domain is <b>https://cn-api.segwaydiscovery.com</b>.`
            "
            v-model="form.regular.segway_portal_base_urL"
          />
        </div>
        <div class="mt-2">
          <AppInput
            rules=""
            type="text"
            :name="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwayUserId'
              )
            "
            :label="
              $t(
                'components.organizationManagement.addEdit.steps.settings.title.segwayUserId'
              )
            "
            placeholder="e.g. 4xhI4fk"
            :infoDescription="
              $t(
                'components.organizationManagement.addEdit.steps.settings.infoDescription.segwayUserId'
              )
            "
            v-model="form.regular.segway_user_id"
          />
        </div>
      </section>
      <!-- /Segway Dashboard Configuration -->

      <!-- web-links -->
      <!-- <section>
        <div class="my-5 section-name" v-text="`Web Links`" />

        <div class="flex items-center">
          <AppInput
            rules=""
            :name="$t('components.organizationManagement.addEdit.steps.settings.title.playStore')"
                :label="$t('components.organizationManagement.addEdit.steps.settings.title.playStore')"
            placeholder="e.g. https://play.google.com/store/apps"
           :infoDescription="$t('components.organizationManagement.addEdit.steps.settings.infoDescription.playStore')"
            v-model="form.link.play_store_link"
          />

          <div class="web-link-icon">
            <i class="fab fa-google-play"></i>
          </div>
        </div>

        <div class="flex items-center">
          <AppInput
            rules=""
              :name="$t('components.organizationManagement.addEdit.steps.settings.title.appleStore')"
                :label="$t('components.organizationManagement.addEdit.steps.settings.title.appleStore')"
            placeholder="e.g. https://apple.com/ios/store/apps"
            :infoDescription="$t('components.organizationManagement.addEdit.steps.settings.infoDescription.appleStore')"
            v-model="form.link.apple_store_link"
          />
          <div class="web-link-icon">
            <i class="fab fa-apple"></i>
          </div>
        </div>

        <div class="flex items-center">
          <AppInput
            rules=""
            :label="$t('components.organizationManagement.addEdit.steps.settings.title.website')"
            placeholder="e.g. https://example.com"
            v-model="form.link.website_link"
            :infoDescription="$t('components.organizationManagement.addEdit.steps.settings.infoDescription.website')"
          />

          <div class="web-link-icon">
            <svg
              class="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
          </div>
        </div>

        <div class="flex items-center">
          <AppInput
            rules="email"
            :label="$t('components.organizationManagement.addEdit.steps.settings.title.email')"
            placeholder="e.g. john@example.com"
              :infoDescription="$t('components.organizationManagement.addEdit.steps.settings.infoDescription.email')"
            v-model="form.link.email_address"
          />

          <div class="web-link-icon">
            <i class="fas fa-envelope"></i>
          </div>
        </div>
      </section> -->
      <!-- /web-links -->

      <button type="submit" ref="submitButton" class="hidden">Save</button>
    </form>
  </ValidationObserver>
</template>

<script>
import { useEndpoints } from '@/composables'
import { deepCompareObjects } from '@/utils'
// import { getFormModel } from '.'
// import differenceBy from 'lodash/differenceBy';
export default {
  name: 'OrgAddEditStep3',

  components: {},

  props: {
    lockIots: {
      type: Array,
      required: true,
    },
    paymentGateways: {
      type: Array,
      required: true,
    },
    primaryKey: {
      required: true,
    },
    formData: {
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    // const { payment, link, domain } = getFormModel({ step: 3 })
    // console.log(payment, link, domain, 'form3')

    return {
      isLoading: false,
      isIotRemoveActionThrottling: false, // use for preventing quick click  behaviour
      form: {
        payment: {
          payment_gateway: '',
          client_id: null,
          secret_key: '',
          app_key: 'apk-1234',
          extra_key: 'exk-1234',
          sandbox_mode: false,
          sandbox_client_id: '',
          sandbox_secret_key: 'apk-1234',
          sandbox_app_key: '',
          supported_payment_method_types: [],
        },
        regular: {
          is_wallet_payment_enabled: true,
          is_cash_payment_enabled: true,
          is_on_ride_payment_enabled: false,
          max_credit_card_count: 10,
          otp_rate_limit: 4,
          otp_rate_mode: 's',
          segway_client_id: '',
          segway_user_id: '',
          segway_client_secret: '',
          segway_portal_base_urL: '',
          okai_command_frame_pass: '',
        },
        // link: {},
        domain: {},
      },

      lockTypes: [],
      toDeleteLockTypes: [],
      isSegwayIotType: false,
      isOkaiIotType: false,
      supportedPaymentMethodTypes: [
        { text: 'PayPal', value: 'PayPal' },
        { text: 'Card', value: 'Card' },
      ],
    }
  },

  computed: {
    getOtpRateModeOptions() {
      return [
        { value: 's', text: 'Per Second' },
        { value: 'm', text: 'Per Minute' },
        { value: 'h', text: 'Per Hour' },
      ]
    },
    isItPayoneMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'Payone') {
        return true
      } else {
        return false
      }
    },
    isItKKiaPayMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'KKiaPay') {
        return true
      } else {
        return false
      }
    },
    isItStripeMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'Stripe') {
        return true
      } else {
        return false
      }
    },
    isItSTSPayoneMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'STS PayOne') {
        return true
      } else {
        return false
      }
    },
    isItMyFatoorahMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'MyFatoorah') {
        return true
      } else {
        return false
      }
    },
    isItBeePayMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'BeePay') {
        return true
      } else {
        return false
      }
    },
    isItMxPlusMethod() {
      const pm = this.paymentGateways.filter(
        (item) => item.id === this.form.payment.payment_gateway
      )
      if (pm.length && pm[0].name === 'MX Plus') {
        return true
      } else {
        return false
      }
    },
    isHideEnableCheckbox() {
      return !this.isItBeePayMethod && !this.isItMxPlusMethod
    },
    getClientIdLabel() {
      if (this.isItStripeMethod) {
        return this.$t(
          'components.organizationManagement.addEdit.steps.settings.title.getClientIdLabel.privateKey'
        )
      }
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.organizationManagement.addEdit.steps.settings.title.getClientIdLabel.merchantId'
        )
      }
      return this.$t(
        'components.organizationManagement.addEdit.steps.settings.title.getClientIdLabel.clientID'
      )
    },
    getSecretKeyLabel() {
      if (this.isItSTSPayoneMethod) {
        return this.$t(
          'components.organizationManagement.addEdit.steps.settings.title.getSecretKeyLabel.authToken'
        )
      } else if (this.isItMyFatoorahMethod) {
        return this.$t(
          'components.organizationManagement.addEdit.steps.settings.title.getSecretKeyLabel.apiToken'
        )
      }
      return this.$t(
        'components.organizationManagement.addEdit.steps.settings.title.getSecretKeyLabel.secretKey'
      )
    },
  },

  watch: {
    // sync props.formData with $data.from
    formData: {
      deep: true,
      immediate: true,
      handler(data) {
        if (data) {
          // todo: if domains are < 1 it might be in draft status
          // if paymentId is empty, it hasn't been created yet (probably in draft status)
          // links are optional fields
          const { payment, paymentId, regular } = data
          if (payment.supported_payment_method_types.length === 0) {
            payment.supported_payment_method_types = ['Card']
          }
          this.form = { payment, paymentId, regular }
          this.form.payment = {
            ...this.form.payment,
            sandbox_mode: !this.form.payment.sandbox_mode,
          }
          console.log(this.form, 'ff3')
        }
      },
    },

    // notify form is dirty & user should confirm before exiting
    form: {
      deep: true,
      immediate: false,
      handler(updatedFormData) {
        // don't notify if editing data & user input data are same
        if (deepCompareObjects(this.formData, updatedFormData)) {
          return
        }
        // otherwise notify whenever there's new input / change
        this.$emit('dirty', { step: 3, data: this.form })
      },
    },
    lockTypes: {
      deep: true,
      immediate: true,
      handler(updatedData) {
        this.isOkaiIotType = updatedData.some((iot) => {
          const type = iot.type.split('_')[0]
          if (type === 'OKAI') {
            return true
          } else {
            return false
          }
        })

        this.isSegwayIotType = updatedData.some((iot) => {
          const type = iot.type.split('_')[0]
          if (type === 'SEGWAY') {
            return true
          } else {
            return false
          }
        })
      },
    },
  },
  async mounted() {
    await this.requestOrganizationVehicleIotTypeSettings()
  },
  methods: {
    async requestOrganizationVehicleIotTypeSettings() {
      let url = useEndpoints.organization.vehicleTypeSettings.index(
        this.primaryKey
      )
      this.isLoading = true
      this.lockTypes = []

      await this.$http
        .get(url)
        .then((res) => {
          console.log('IoT Types', res.data.data)
          this.lockTypes = res.data?.data.map((entry) => {
            return {
              id: entry.id,
              type: entry.iot_category,
              name: entry.iot_category_name,
              domain: entry.domain ? entry.domain : '',
              port: entry.port,
            }
          })
        })
        .catch((err) => {
          console.log(err.response)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onAddLockTypeField() {
      this.lockTypes.unshift({ type: '', domain: '', port: '' })
    },
    onRemoveLockTypeField(index) {
      if (this.isIotRemoveActionThrottling) {
        return
      }
      this.isIotRemoveActionThrottling = true

      setTimeout(() => {
        this.isIotRemoveActionThrottling = false
        if (this.isEditing) {
          let toRemoveType = this.lockTypes[index]
          if (
            this.lockTypes.filter((entry) => entry.type === toRemoveType.type)
              .length > 0
          )
            this.toDeleteLockTypes.push(this.lockTypes[index])
        }
        this.lockTypes.splice(index, 1)
      }, 500)
    },

    async submit() {
      await this.$refs.submitButton.click()
    },
    async submitStep() {
      /**
       * Payment
       *
       * in draft status -> the payment gateway might not been created,
       * meaning there's no paymentId, in that case, it need to be created.
       */
      const paymentId = this.form.paymentId

      const paymentUrl = paymentId
        ? useEndpoints.paymentGateway.update(paymentId)
        : useEndpoints.paymentGateway.create()

      const paymentMethod = paymentId ? 'PATCH' : 'POST'

      console.log({ paymentMethod })

      const paymentData = new FormData()

      const jsonstring_supported_payment_method_types = JSON.stringify(
        this.form.payment.supported_payment_method_types
      )

      const paymentFormData = {
        ...this.form.payment,
        sandbox_mode: !this.form.payment.sandbox_mode,
        supported_payment_method_types: jsonstring_supported_payment_method_types,
        client_id:
          this.form.payment.client_id === ''
            ? null
            : this.form.payment.client_id,
      }
      paymentFormData.organization = this.primaryKey

      for (const k in paymentFormData) {
        paymentData.append(k, paymentFormData[k])
      }

      const paymentReq = this.$http({
        url: paymentUrl,
        method: paymentMethod,
        data: paymentData,
      })

      /**
       * Regular
       *
       * Regular are always org patch req, if primaryKey is there.
       * The user won't make to this step if there's no primary key.
       * Skipping the primary key check.
       */
      const regularUrl = useEndpoints.organization.update(this.primaryKey)
      const regularMethod = 'PATCH'
      const regularData = new FormData()
      for (const k in this.form.regular) {
        console.log('regular', k, this.form.regular[k])
        regularData.append(k, this.form.regular[k])
      }

      const regularReq = this.$http({
        url: regularUrl,
        method: regularMethod,
        data: regularData,
      })

      /**
       * Links
       *
       * Links are always org patch req, if primaryKey is there.
       * The user won't make to this step if there's no primary key.
       * Skipping the primary key check.
       */

      // const linkUrl = useEndpoints.organization.update(this.primaryKey)

      // const linkMethod = 'PATCH'
      // console.log('pk', this.primaryKey)

      // const linkData = new FormData()
      // for (const k in this.form.link) {
      //   console.log('link', k, this.form.link[k])
      //   linkData.append(k, this.form.link[k])
      // }

      // const linkReq = this.$http({
      //   url: linkUrl,
      //   method: linkMethod,
      //   data: linkData,
      // })

      /**
       * IoT types
       *
       * in draft status -> the iot types might not been created,
       * meaning there's iot types for organization from request, in that case, types need to be created.
       * Also, in editing mode, removed iot's need to be deleted to sync the data
       */
      let iotTypeRequests = []
      if (this.lockTypes.length) {
        this.lockTypes.forEach((element) => {
          console.log(`Elem ${element.type}`, element)
          const isUpdateReq = 'id' in element
          const iotTypeMethod = isUpdateReq ? 'PATCH' : 'POST'
          const iotTypeUrl = isUpdateReq
            ? useEndpoints.organization.vehicleTypeSettings.update(element.id)
            : useEndpoints.organization.vehicleTypeSettings.create(
                this.primaryKey
              )

          const iotData = new FormData()
          iotData.append('iot_category', element.type)

          if (typeof element.domain === 'string')
            iotData.append('domain', element.domain)

          if (element.port) {
            if (`${element.port}`.trim() === '' || !isNaN(element.port)) {
              iotData.append('port', element.port)
            }
          }
          // console.log(`${element.type}`, iotData)

          const iotReq = this.$http({
            url: iotTypeUrl,
            method: iotTypeMethod,
            data: iotData,
          })
          iotTypeRequests.push(iotReq)
        })
      }

      if (this.isEditing && this.toDeleteLockTypes.length) {
        this.toDeleteLockTypes.forEach((element) => {
          if ('id' in element) {
            const iotReq = this.$http({
              url: useEndpoints.organization.vehicleTypeSettings.update(
                element.id
              ),
              method: 'DELETE',
            })
            iotTypeRequests.push(iotReq)
          }
        })
      }

      const requests = [paymentReq, regularReq, ...iotTypeRequests]

      // submit
      this.isLoading = true
      await this.$http
        .all(requests)
        .then(
          this.$http.spread((...responses) => {
            const [paymentRes, regularRes, ...iotTypeResps] = responses
            console.log('iotTypeResps', iotTypeResps.length)
            // let iotTypeData = iotTypeResps.map((res) => res.data?.data)
            const data = {
              payment: paymentRes.data,
              // link: linkRes.data,
              regular: regularRes.data,
              // iotResps: iotTypeData,
            }

            // note raw responses are being sent
            this.$emit('save', { step: 3, data })

            const message = `Organization setings ${
              this.isEditing ? 'updated' : 'added'
            } successfully`

            this.$notify({
              group: 'bottomLeft',
              type: 'success',
              title: 'Success',
              text: message,
            })
          })
        )
        .catch((err) => {
          // todo: emit fail?
          console.error('Error occured!', err)
          this.$notify({
            group: 'bottomLeft',
            type: 'error',
            title: 'Error occured!',
            text: err.response.data.detail,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
// .web-link-icon {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 48px;
//   height: 42px;
//   background: rgba(243, 244, 246, 1);
//   border: 1px solid rgba(203, 213, 224, 1);
//   margin-left: 0.25rem;
//   border-radius: 0.25rem;
// }
.minus-btn {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  background: #d90a20;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.section-name {
  @apply text-sm font-bold text-gray-500;
}
</style>
