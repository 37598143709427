<template>
  <EdgeStack
    :id="esId"
    :busy="busy"
    :confirmQuestion="$t('components.edgeStack.confirmQuestion')"
  >
    <template v-slot:header>
      <div class="pb-3 panel-title">{{ getTitleText }}</div>

      <!-- <XStepper
        :id="esId"
        :steps="stepperSteps"
        :mode="stepperMode"
        v-model="stepperCurrentStep"
        ref="xstepper"
      /> -->
    </template>

    <template v-slot:footer>
      <XStepperNavigation
        v-if="isEditing"
        :id="esId"
        :steps="stepperSteps"
        :showNavigationButton="false"
        :submit-button-text="getSubmitButtonText"
        @submit="onSubmit"
        v-model="stepperCurrentStep"
      />

      <AppButton
        v-if="!isEditing"
        :text="getSubmitButtonText"
        @click="onSubmit({ step: stepperCurrentStep })"
      />
    </template>

    <template #default>
      <keep-alive>
        <template v-if="stepperCurrentStep === 1">
          <Step1
            ref="step1"
            :primary-key="primaryKeyProxy"
            :form-data="getStep1FormData"
            :fleets="fleets"
            :isTaxPlanLoading="isTaxPlanLoading"
            :taxPlans="taxPlans"
            :vehicle-types="vehicleTypes"
            :rentTypeChoices="rentTypeChoices"
            :pricingPlanType="pricingPlanType"
            :isRentPackageShow="isRentPackageShow"
            :is-editing="isEditing"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template>

        <!-- <template v-else-if="stepperCurrentStep === 2">
          <Step2
            ref="step2"
            :primary-key="primaryKeyProxy"
            :form-data="getStep2FormData"
            :is-editing="isEditing"
            :isPackageDataLoaded="isPackageDataLoaded"
            :taxPlanEnabled="taxPlanEnabled"
            :taxPercentage="taxPercentage"
            :fleets="fleets"
            :rentTypeChoices="rentTypeChoices"
            :rentAddonsChoices="rentAddonsChoices"
            :fleetKeyProxy="fleetKeyProxy"
            @save="onSave($event)"
            @dirty="onDirty(true)"
          />
        </template> -->
      </keep-alive>
    </template>
  </EdgeStack>
</template>

<script>
// import pick from 'lodash/pick'

// import { useEndpoints } from '@/composables'

import { AppButton } from '@/components/form'
import { EdgeStack } from '@/components/modals'
import { XStepperNavigation } from '@/components/stepper'

import Step1 from '@/views/pricing/add-edit-bill/Step1.vue'
// import Step2 from '@/views/pricing/add-edit-bill/Step2.vue'

// import { getVehicleFormModel } from './index'

import { useEndpoints } from '@/composables'
import { BillingPlanConfig } from '@/config/BillingPlanConfig'
import { vehicleTypesForOrg } from '@/utils'
export default {
  name: 'BillAddEdit',

  components: {
    EdgeStack,
    XStepperNavigation,
    AppButton,
    Step1,
    // Step2,
  },

  props: {
    esId: {
      type: String,
      default: 'bill-add-edit',
    },
    stepperMode: {
      type: String,
      default: 'free',
    },
    stepperStep: {
      type: Number,
      default: 1,
    },
    // billingPlan.id
    primaryKey: {
      required: false,
    },

    rawData: {
      type: Object,
      required: false,
    },
    pricingPlanType: {
      type: String,
      default: BillingPlanConfig.type.public,
    },
    busy: {
      type: Boolean,
      default: false,
    },
    rentalEnabled: {
      type: Boolean,
      default: false,
    },
    rentPackages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const stepperSteps = [
      {
        title: this.$t('components.billingPlanManagement.addEdit.tabs.ride'),
        free: true,
      },
      // {
      //   title: this.$t('components.billingPlanManagement.addEdit.tabs.rent'),
      //   free: false,
      // },
    ]

    if (!this.rentalEnabled) stepperSteps.pop()
    return {
      isLoading: false,
      isTaxPlanLoading: false,
      taxPlanEnabled: false,
      taxPercentage: 0,
      isRentPackageShow: false,
      errors: [],
      // Unless a step is free, user can't navigate to that step by clicking on the step
      // todo: also support this feature on xStepperNavigation & xStepper.navigate() helpers

      stepperSteps,
      // this is the proxy of prop.stepperStep. It's used as v-model for steps
      // it needs to be in sync -> watched
      stepperCurrentStep: 1,

      // following data will be collected from step 1 & required for both steps
      selectedLockId: '', // <-- id: [uuid]

      // following data are required for the stepper steps
      fleets: [],
      taxPlans: [],
      vehicleTypes: [],
      rentTypeChoices: [],
      rentAddons: [],
      rentAddonsChoices: [],
      isPackageDataLoaded: false,
      primaryKeyProxy: null,
      fleetKeyProxy: null,
    }
  },

  computed: {
    // User data is meant to be passed to be in edit mode
    // ? is it better to also check stepperMode? i'll think about it later
    // todo: don't allow navigating to next step in editing mode if current step is dirty
    // todo: -> need to save dirty state with step data, pass prob allowNext & allowPrev on XStepperNavigation component

    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    isEditing() {
      return !!this.rawData
    },

    getTitleText() {
      return this.isEditing
        ? this.$t('components.billingPlanManagement.addEdit.headline.update')
        : this.$t('components.billingPlanManagement.addEdit.headline.add')
    },

    getSubmitButtonText() {
      return this.isEditing
        ? this.$t('components.stepNavigation.update')
        : this.$t('components.stepNavigation.save')
    },
    getStep1FormData() {
      if (this.rawData && this.rentPackages) {
        const rideDetailsOfBilling = this.rawData
        console.log('getStep1FormData', this.rentPackages)
        // rideDetailsOfBilling.tax_plan = rideDetailsOfBilling.tax_plan
        //   ? rideDetailsOfBilling.tax_plan.id
        //   : null

        // rideDetailsOfBilling.existing_fleet = rideDetailsOfBilling.fleet

        // rideDetailsOfBilling.fleet = rideDetailsOfBilling.fleet
        //   ? rideDetailsOfBilling.fleet.id
        //   : null
        return {
          rentPlanData: { ...rideDetailsOfBilling },
          rentPackages: this.rentPackages,
        }
      }

      return null
    },
    getStep2FormData() {
      if (this.rawData) {
        const rentData = {
          vehicle_rental_per_hour_fees: this.rawData
            .vehicle_rental_per_hour_fees,
          minimum_rental_fee: this.rawData.minimum_rental_fee,
          rentPackages: this.rentPackages,
          rentAddons: this.rentAddons,
        }
        return rentData
      }

      return null
    },
  },
  async created() {
    await this.fetchData()
  },

  async mounted() {
    // reset current step to 1 on closed
    this.$edgeStack.emitter.on(
      this.$edgeStack.getEventName('closed', this.esId),
      () => {
        this.stepperCurrentStep = 1
      }
    )
  },

  beforeDestroy() {
    this.$edgeStack.emitter.off(
      this.$edgeStack.getEventName('closed', this.esId)
    )
  },

  watch: {
    primaryKey: {
      deep: false,
      immediate: true,
      handler(updatedId) {
        // primaryKey -> updatedId could be null (in add mode)
        if (updatedId) {
          this.primaryKeyProxy = updatedId
        }
      },
    },

    stepperStep: {
      deep: false,
      immediate: true,
      handler(updatedStep) {
        this.stepperCurrentStep = updatedStep
        console.log('st', this.stepperCurrentStep)
      },
    },

    rawData: {
      deep: false,
      immediate: true,
      handler(data) {
        if (data) {
          this.isPackageDataLoaded = true
          this.fleetKeyProxy = data.fleet.id

          this.taxPlanEnabled = !!data.tax_plan
          if (data.tax_plan) {
            this.taxPercentage = Number(data.tax_plan.percentage)
          }
        }
      },
    },
  },

  methods: {
    onDirty(type, id = this.esId) {
      return type === true
        ? this.$edgeStack.shouldConfirm(id)
        : this.$edgeStack.shouldNotConfirm(id)
    },

    async onSave(e = { step: null, data: {} }) {
      // console.log('data-bl', e)
      if (e.step === 1) {
        this.primaryKeyProxy = e.data.id
        this.fleetKeyProxy = e.data.fleet.id ? e.data.fleet.id : e.data.fleet
        this.taxPlanEnabled = e.taxPlanEnabled
        this.taxPercentage = e.taxPercentage
        // free all steps

        this.onLastStep()
      }

      // if (e.step === 2) {
      //   // free only first step -> as we'r about to reset & close -> set it to initial state
      //   this.$xStepper.defineSteps(this.esId, this.stepperSteps)
      //   this.onLastStep()
      // }
    },

    beforeNextStep({ to = 2 }) {
      // define what happens about confirmation before exiting on next step (2)
      // on edit mode->after updating->should not confirm unless event triggered by user
      // on add mode->after saving->should confirm
      if (this.stepperMode === 'free') {
        this.$edgeStack.shouldNotConfirm(this.esId)
      } else {
        this.$edgeStack.shouldConfirm(this.esId)
      }

      if (to) {
        this.$xStepper.navigate(this.esId).to(to)
      } else {
        this.$xStepper.navigate(this.esId).next()
      }
    },

    onLastStep() {
      // notify that the table view needs to be updated
      this.$emit('refresh')
      dispatchEvent(new Event(BillingPlanConfig.events.refresh))
      // Should not confirm as we'r about to close it
      this.$edgeStack.shouldNotConfirm(this.esId)
      this.$edgeStack.close(this.esId)

      this.$edgeStack.emitter.on(
        this.$edgeStack.getEventName('closed', this.esId),
        () => {
          this.stepperCurrentStep = 1
        }
      )
    },

    onSubmit(step) {
      this.$refs[`step${step.step}`].submit()
    },

    async fetchData() {
      this.isTaxPlanLoading = true
      this.fleets = await this.$http
        .get(useEndpoints.dropdown.fleets())
        .then((res) => res.data.data)
        .catch((err) => console.log('fleetsErr = ', err.response))

      this.taxPlans = await this.$http
        .get(`${useEndpoints.taxPlan.index}?export=true`)
        .then((res) => res.data.data)
        .catch((err) => console.log(err))
        .finally(() => (this.isTaxPlanLoading = false))

      this.$http
        .get(useEndpoints.pricingPlan.getRentPackageTypeChoice())
        .then((res) => (this.rentTypeChoices = res.data))
        .catch((err) => console.log('rentTypeChoicesErr = ', err.response))

      this.$http
        .get(useEndpoints.organization.details(this.orgId))
        .then(
          (res) =>
            (this.isRentPackageShow =
              res.data.should_display_trip_packages_before_unlocking_vehicle)
        )
        .catch((err) => console.log('orgDetailsErr = ', err.response))

      // this.$http
      //   .get(useEndpoints.rentPricingPlan.getRentAddonsChoice())
      //   .then((res) => (this.rentAddonsChoices = res.data))
      //   .catch((err) => console.log('rentAddonsChoicesErr = ', err.response))

      // set vehicle type dropdown based on subscription

      const orgVehicleTypes = this?.$org?.vehicle_type || []
      orgVehicleTypes.forEach((t) =>
        this.vehicleTypes.push(vehicleTypesForOrg[t])
      )
    },
  },
}
</script>

<style scoped>
.panel-title {
  font-size: 22px;
  font-weight: 500;
  color: #2e2e39;
  margin-top: -30px;
  margin-bottom: 6px;
}
</style>
